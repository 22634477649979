/* eslint-disable @typescript-eslint/no-var-requires */
import {ConfigurationApplication} from '@application/Config';
import {getAuth} from '@infra/api/authManager/authManager';
import {proxy} from '@infra/api/fetch/proxy';
import {Environment} from '@domain/model/Config/Configuration';
import {bootstrap, translationExternalEndpoint, SentryEnvironment, KameleoonEnvironment} from '@lookiero/style-profile';
import packageJson from '@lookiero/style-profile/package.json';
import {Platform} from 'react-native';
import {PROJECT_ID} from './sentryConfig';
import {Path} from '../routing/routes.definition';

const sentry = (): SentryEnvironment => {
  const environment = ConfigurationApplication.retrieve().environment === Environment.DEVELOPMENT ? 'DEV' : 'PROD';

  return {
    publicKey: 'bf9ded71e97254c526526bf5cc878aa1',
    release: packageJson.version,
    project: PROJECT_ID,
    environment: `${Platform.OS}-${environment}` as const,
  };
};

const translations = [
  (locale: string) =>
    proxy(
      translationExternalEndpoint({
        translationsUrl: '/api/v2/translations',
        projects: [['user-area-front'], ['style-profile']],
      })(locale),
    ),
];

const KAMELEOON_DEV = {
  siteCode: 'aplm4v3ckn',
  experiment: {},
} as KameleoonEnvironment;

const KAMELEOON_PROD = {
  siteCode: '0zj7mtr4bm',
  experiment: {},
} as KameleoonEnvironment;

const kameleoon = (): KameleoonEnvironment =>
  ConfigurationApplication.retrieve().environment === Environment.DEVELOPMENT ? KAMELEOON_DEV : KAMELEOON_PROD;

const {root, nextBestActionRoot} = bootstrap({
  apiUrl: () => proxy('/style-profile/api'),
  getAuthToken: getAuth as unknown as () => Promise<string>,
  translations,
  sentry,
  kameleoon,
  basePath: Path.STYLE_PROFILE_HOME,
});

export {root, nextBestActionRoot};

import {Locale} from '@lookiero/style-profile';
import {useEffect, useState} from 'react';
import {viewLocaleHandler} from '../../../locale/core/infrastructure/projection/viewLocale';
import {viewLocale} from '../../../locale/core/projection/viewLocale';
import {viewCustomer} from '../../../customer/core/projection/viewCustomer';
import {viewCustomerHandler} from '../../../customer/core/infrastructure/projection/viewCustomer';
import {Customer} from '../../../customer/core/projection/customer';
import {useApp} from '../../../../ui/app';

interface UseViewCustomerAndLocaleFunctionReturn {
  readonly customer: Customer | null;
  readonly locale: Locale | null;
}

interface UseViewCustomerAndLocaleFunction {
  (): UseViewCustomerAndLocaleFunctionReturn;
}

const useViewCustomerAndLocale: UseViewCustomerAndLocaleFunction = () => {
  const {authorization} = useApp();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [locale, setLocale] = useState<Locale | null>(null);

  useEffect(() => {
    const loadCustomer = async (): Promise<void> => {
      setCustomer(await viewCustomerHandler(viewCustomer()));
    };

    loadCustomer();
  }, [authorization]);

  useEffect(() => {
    const loadLocale = async (): Promise<void> => {
      setLocale((await viewLocaleHandler(viewLocale())) as Locale);
    };

    loadLocale();
  }, []);

  return {
    customer,
    locale,
  };
};

export {useViewCustomerAndLocale};
